* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: auto;
  left: 0;
  right: 0;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
}

.disclaimer,
img[alt*="000webhost"],
img[alt*="000webhost"][style],
img[src*="000webhost"],
img[src*="000webhost"][style],
body>div:nth-last-of-type(1)[style] {
  opacity: 0;
  pointer-events: none;
  width: 0px;
  height: 0px;
  visibility: hidden;
  display: none;
}

#tytul {
  text-align: center;
  background-color:#99ff99;
  padding: 8em 1em .5em 1em;

}

h1{
  font-size: 32px;
}

.input {
  text-align: center;
  width: 8em;
  height: 1.8em;
  color: #0077FF;
  font-weight: bold;
  font-size: 1.2em;
  outline: none;
  letter-spacing: 3px;
  transition: .5s;
  border-radius: .35em;
  margin-top: .5em;
}

.input:focus {
  box-shadow: 0px 0px 15px black;
}

.input:hover {
  cursor: pointer;
}

.wynik {
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 0;
  position: fixed;
  font-size: 1.3em;
  background-color: #94FD37;
  padding: 10px;
  box-shadow: 0px 0px 15px;
  text-shadow: 3px 3px 5px grey;
  font-weight: 700;
  background-image: linear-gradient(to right, #222, #54AD00, #94FD37, #90FF50, #AAFFAA, #90FF50, #94FD37, #54AD00, #222);
  z-index: 99;
}


ol {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 20px;
  align-content: space-between;
  justify-content: space-evenly;
  padding: 2em;
  font-weight: 300;
}

footer {
  padding-top: 2em;
  padding-bottom: 1em;
  color: white;
  font-size: 0.8em;
  line-height: 1.7em;
  background-color: #444;
}

.box {
  text-align: left;
}

#box,
#ppk {
  margin-right: 1em;
  height: 2em;
  width: 2em;
}

.span {
  font-weight: bold;
  color: #99ff99;
  letter-spacing: 1px;
}

.img {
  width: 5.5em;
  height: 5.5em;
  margin-top: -1em;
}

.list {
  padding: 2% 10%;
  background-color: white;
  text-align: left;
}

.count {
  text-align: right;
  padding-left: 10px;
  padding-right: 5px;
  letter-spacing: 1px;
  font-weight: bold;
}

#constInp {
  padding: 2em;
  background-color: #99ff99;
  font-weight: 600;
}

.icon{
  width: 1.5em;
  height: 1.5em;
  margin-bottom: -3px;
  background-color: white;
  padding: 2px;
}

.icon[alt='wind'],[alt='pressure'],[alt='clouds']{
  margin-left: 1em;
}

li {
  padding: 1em;
  border: 1px solid lightgrey;
  margin: 1em;
  background-color: #eeffee;
  border-radius: .5em;
  flex-grow: 1;
}

.small{
  font-size: 12px;
  text-align: left;
}

table, th, td{
  border: 1px solid;
  border-collapse: collapse;
  padding: .5em;
}

.desc{
  left: 0;
  right: 0;
  margin: auto;
  text-align: left;
  padding: 20px;
  max-width: 800px;
  line-height: 1.5em;
}
p{
  padding: .5em;
}
::placeholder{
  color: black;
  opacity: 1;
}